import * as React from "react";

const NotFoundPage = () => {

  return (
    <div className="container">
      <h1>404: Not Found</h1>
    </div>
  )
}

export default NotFoundPage
